import { all, takeLatest, put, select, take, call, delay } from 'redux-saga/effects';

import AuthActionTypes, { IFinalizeProfileAction } from './auth.types';
import {
  loginSuccess,
  signupSuccess,
  setAuth,
  loginError,
  passwordRecoverySuccess,
  passwordRecoveryError,
  setNewRecoverPasswordError,
  setAuthErrorCodes,
  refreshUserSuccess,
  refreshUserFailure,
  setRefreshUserErrors,
  setIsNotLoading,
  setIsInitialAuthInProgress,
  resetAuthErrors,
} from './auth.actions';
import { microsoftTeamsContextSelector, authSelector } from './auth.selectors';
import AuthErrorTypes from './auth.errors';

import * as calls from '../api/calls/auth.calls';
import * as authActions from '../auth/auth.actions';
import {
  ISigninCredentials,
  IGetBitesTokenFromSocialBody,
  TokenTypes,
  IPasswordRecoveryBody,
  INewPasswordCredentials,
  ILoginWithPhone,
  ISignupPhone,
  ISignupEmailCredentials,
  IFinalizeProfileDataRequest,
} from '../../types/auth';
import { IAction } from '../types';
import { checkForRedirectionAfterAuthAndRedirect } from '../../utils/botAuthRedirection';
import microsoftTeams from '../../services/msTeams';
import history from '../../navigation/history';
import { getRedirectUri, setRedirectUri } from '../../utils/redirection';
import {
  getOrgSettings,
  getOrgSettingsFail,
  getOrgSettingsSuccess,
  resetOrgSettings,
  setBranding,
} from '../organization/organization.slice';
import localStorage from '../../utils/localStorage';
import gtmTrack, { gtmSetUserId, gtmSetUserProps } from '../../services/googleTagManager/track';
import { applyLocaleIfNeeded } from '../../locale/i18n';
import { orgSelector } from '../organization/organization.selectors';
import handleHttpErrors from '../../utils/errors/handleHttpErrors';
import { log, logError } from '../tracking/tracking.slice';
import store from '../store';
import { cloneDeep } from 'lodash';
import { setIsAuthDataMissing, setMissedAuthData } from '../authForm/authForm.slice';
import { APP } from '../../constants/app';
import { setShowSomethingWentWrong } from '../appActivity/appActivity.slice';
import { v4 as uuid } from 'uuid';
import { isAuthDataMissingSelector } from '../authForm/authForm.selectors';
import { EShowSomethingWentWrongError } from '../appActivity/appActivity.types';
import { setBitesApiAuthorization } from '../api';
import { applyOnboardingToken } from '../onboarding/onboarding.saga';
import { getQueryParam, setQueryParam } from '../../utils/queryParams';

function* logout(action?: IAction<{ noRedirection?: boolean; redirectUrl?: string; saveRedirectUri?: boolean }>) {
  yield put(
    log({
      event: 'auth.saga logout: start',
      data: {
        payload: action?.payload,
      },
    }),
  );

  const { noRedirection, redirectUrl = '/auth', saveRedirectUri } = action?.payload || {};
  yield storeAndSetToken(null);
  yield clearLocalStorage();
  yield put(setAuth(null));
  yield put(resetOrgSettings());

  calls.logout().catch((error) => {
    store.dispatch(
      log({
        event: 'auth.saga logout: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
  });

  const query = new URLSearchParams(history.location.search);
  const redirectUri = query.get('redirect');
  if (!noRedirection) {
    setRedirectUri({ pathname: redirectUrl, redirectUri: saveRedirectUri ? redirectUri : null });
    return;
  }
  if (!saveRedirectUri) {
    setRedirectUri({ redirectUri: null, replace: true });
  }
}

function* login(action: IAction<ISigninCredentials>) {
  const { username, password, otp, processId, onOtp } = action.payload;

  const loginCredentials: ISigninCredentials = {
    username,
    password,
    otp,
    app: APP,
  };

  yield put(
    log({
      event: 'auth.saga login: start',
      processId,
      data: { loginCredentials },
    }),
  );

  try {
    yield put(resetAuthErrors());

    const res = yield calls.login(loginCredentials);
    const { token, user } = res.data;

    yield put(
      log({
        event: 'auth.saga login: result',
        processId,
        data: {
          token,
          user,
        },
      }),
    );

    yield setMissedAuthDataSaga({
      event: 'auth.saga login: missingAuthData',
      processId,
      user,
    });

    yield getOrgSettingsSaga({ organizations: user.organizations });

    yield put(
      log({
        event: 'auth.saga login: getOrgSettingsIfNeeded done',
      }),
    );

    yield storeAndSetToken(token, 'jwt_token');

    // redirections
    const isRedirecting = yield checkForRedirectionAfterAuthAndRedirect(token, 'jwt_token');
    if (isRedirecting) {
      return;
    }

    yield redirectAfterAuth(); // redirect before setting the user to the store to avoid unneeeded redirections and calculations

    // set user
    yield put(setAuth({ user }));
    yield applyOnboardingToken();
    yield put(loginSuccess());

    // microsoft teams
    yield notifyMsAuthSuccessIfNeeded();

    gtmTrack('login', {
      login_type: 'email',
      status: 'Success',
      'gtm.userId': user.id,
    });
  } catch (err) {
    yield call(handleHttpErrors, {
      event: 'auth.saga login: error',
      processId,
      error: err,
      showSomethingWentWrongError: EShowSomethingWentWrongError.AUTH,
    });
    yield put(authActions.setIsNotLoading());

    const status = err.response?.status;
    const error = err.response?.data;

    if (status === 401 && error?.verified === false && !otp) {
      if (typeof onOtp === 'function') {
        onOtp(processId);
      }
      // return - this is not an error case
      return;
    }

    if (status === 401 && error.verified === false) {
      yield put(authActions.setWrongVerificationCode(true));
      return;
    }

    // fix this weird logic when we clearly define the error codes
    if (!error?.error_codes) {
      if (error?.message === 'Your username or password is incorrect, Please try again.') {
        yield put(setAuthErrorCodes(['auth.wrong_credentials']));
      } else {
        yield put(setAuthErrorCodes(error?.error_codes || []));
      }
    } else {
      // error code might not be present
      yield put(setAuthErrorCodes(error?.error_codes || []));
    }
    // this handles the else case
    yield put(loginError());

    gtmTrack('login_error', {
      login_type: 'email',
      login_error_message: AuthErrorTypes.wrongEmailOrPassword,
    });
  }
}

function* loginWithPhone(action: IAction<ILoginWithPhone>) {
  const { credentials, onOtp, processId } = action.payload;

  yield put(
    log({
      event: 'auth.saga loginWithPhone: start',
      processId,
      data: credentials,
    }),
  );

  try {
    yield put(resetAuthErrors());

    const { data } = yield calls.loginWithPhone(credentials);

    yield put(
      log({
        event: 'auth.saga loginWithPhone: result',
        processId,
        data,
      }),
    );

    const { token, user } = data;

    yield setMissedAuthDataSaga({
      event: 'loginWithPhone: missingAuthData',
      processId,
      user,
    });

    yield getOrgSettingsSaga({ organizations: user.organizations, queryOrgOnly: true });

    yield storeAndSetToken(token, 'jwt_token');

    // redirections
    const isRedirecting = yield checkForRedirectionAfterAuthAndRedirect(token, 'jwt_token');
    if (isRedirecting) {
      return;
    }

    yield redirectAfterAuth();

    // set user
    yield put(setAuth({ user }));
    yield applyOnboardingToken();
    yield put(loginSuccess());

    gtmTrack('login', {
      login_type: 'phone',
      login_status: 'Success',
      'gtm.userId': user?.id,
    });
  } catch (err) {
    yield call(handleHttpErrors, {
      event: 'loginWithPhone: error',
      processId,
      error: err,
      showSomethingWentWrongError: EShowSomethingWentWrongError.AUTH,
    });
    yield put(authActions.setIsNotLoading());

    const status = err.response?.status;
    const error = err.response?.data;

    // otp case
    if (status === 401 && error?.verified === false && !credentials.otp) {
      if (typeof onOtp === 'function') {
        onOtp(processId);
      }
      // return - this is not an error case
      return;
    }

    if (status === 401 && error?.verified === false) {
      gtmTrack('login_error', {
        login_type: 'phone',
        login_error_message: AuthErrorTypes.invalidAccessCode,
      });
      yield put(authActions.setWrongVerificationCode(true));
      return;
    }

    if (status === 400 && !credentials.otp) {
      gtmTrack('login_error', {
        login_type: 'phone',
        login_error_message: AuthErrorTypes.phoneNumberNotExists,
      });
      yield put(authActions.setIsPhoneMissing(true));
      return;
    }

    // error code might not be present
    yield put(setAuthErrorCodes(error?.error_codes || []));
    // this handles the else case
    yield put(loginError());

    gtmTrack('login_error', {
      login_type: 'phone',
    });
  }
}

function* socialLogin(action: { payload: IGetBitesTokenFromSocialBody & { email?: string }; type: string }) {
  const microsoftContext: microsoftTeams.Context | null = yield select(microsoftTeamsContextSelector);

  let authRes;
  const loginType = action?.payload.backend.includes('azuread')
    ? 'microsoft'
    : action?.payload.backend.includes('google')
    ? 'google'
    : action?.payload.backend;

  try {
    yield put(resetAuthErrors());

    yield put(
      log({
        event: 'auth.saga socialLogin: start',
        data: {
          payload: action.payload,
        },
      }),
    );

    const response = yield calls.getBitesAccessTokenWithSocialToken(action.payload);

    yield put(
      log({
        event: 'auth.saga socialLogin: result',
        data: response.data,
      }),
    );

    const bitesAccessToken = response.data.access_token as string;
    yield storeAndSetToken(bitesAccessToken, 'oauth_token');

    if (microsoftContext?.tid) {
      yield put(
        log({
          event: 'auth.saga socialLogin: addTenantToMicrosoftUser',
          data: {
            microsoftContext,
          },
        }),
      );

      yield calls.addTenantToMicrosoftUser({ tenant_id: microsoftContext.tid });

      yield put(
        log({
          event: 'auth.saga socialLogin: addTenantToMicrosoftUser result',
        }),
      );
    }

    authRes = yield calls.getLoggedInUser();
    const user = authRes.data;

    yield setMissedAuthDataSaga({
      event: 'socialLogin: missingAuthData',
      user,
    });

    yield getOrgSettingsSaga({ organizations: user.organizations });

    yield put(
      log({
        event: 'auth.saga socialLogin: getLoggedInUser result',
        data: {
          user,
        },
      }),
    );

    // redirections
    const isRedirecting = yield checkForRedirectionAfterAuthAndRedirect(bitesAccessToken, 'oauth_token');
    if (isRedirecting) {
      return;
    }

    yield redirectAfterAuth();

    // set user
    yield put(setAuth({ user }));
    yield applyOnboardingToken();
    yield put(authActions.socialLoginEnd({ error: false }));

    // microsoft teams
    yield notifyMsAuthSuccessIfNeeded();

    gtmTrack('login', {
      login_type: loginType,
      login_status: 'Success',
      'gtm.userId': user.id,
    });
  } catch (err) {
    gtmTrack('login_error', {
      login_type: loginType,
      'gtm.userId': authRes?.data.id,
    });

    yield call(handleHttpErrors, {
      event: 'auth.saga socialLogin: error',
      error: err,
      showSomethingWentWrongError: EShowSomethingWentWrongError.AUTH,
    });

    const error = err.response?.data;

    // error code might not be present
    yield put(setAuthErrorCodes(error?.error_codes || []));
    // this handles the else case
    yield put(authActions.socialLoginEnd({ error: true }));
  }
}

export const getUserHasMissedAuthData = (user) => {
  return !user?.first_name && !user?.last_name;
};

function* setMissedAuthDataSaga({ event = 'setMissedAuthDataSaga', processId = undefined, user }) {
  const hasMissedAuthData = getUserHasMissedAuthData(user);

  if (hasMissedAuthData) {
    yield put(
      log({
        event,
        processId,
        data: {
          user,
        },
      }),
    );

    yield put(
      setMissedAuthData({
        isAuthDataMissing: true,
        firstName: user?.first_name || '',
        lastName: user?.last_name || '',
        email: user?.email || '',
        phone: user?.phone || '',
      }),
    );

    setRedirectUri({
      pathname: '/auth/signin',
      redirectUri: window.location.pathname + window.location.search,
    });
  }
}

function* signupWithEmail({ payload }: IAction<ISignupEmailCredentials>) {
  const { processId, onOtp } = payload;
  const body = {
    email: payload.email,
    password1: payload.password,
    password2: payload.password,
    first_name: payload.first_name,
    last_name: payload.last_name,
    organizations: [],
    creator: false,
    app: APP,
  };

  yield put(
    log({
      event: 'auth.saga signupWithEmail: start',
      processId,
      data: body,
    }),
  );

  try {
    yield put(resetAuthErrors());

    const res = yield calls.signupWithEmail(body);
    const { token, user } = res.data;

    yield put(
      log({
        event: 'auth.saga signupWithEmail: result',
        processId,
        data: {
          token,
          user,
        },
      }),
    );

    yield setMissedAuthDataSaga({
      event: 'signupWithEmail: missingAuthData',
      processId,
      user,
    });

    yield getOrgSettingsSaga({ organizations: user.organizations, queryOrgOnly: true });

    yield storeAndSetToken(token, 'jwt_token');

    // redirections
    const isRedirecting = yield checkForRedirectionAfterAuthAndRedirect(token, 'jwt_token');
    if (isRedirecting) {
      return;
    }

    yield redirectAfterAuth();

    // set user
    yield put(setAuth({ user: user }));
    yield applyOnboardingToken();
    yield put(signupSuccess());

    gtmTrack('signup', {
      signup_type: 'email',
      signup_status: 'Success',
      'gtm.userId': user.id,
    });
  } catch (err) {
    yield call(handleHttpErrors, {
      event: 'auth.saga signupWithEmail: error',
      error: err,
      processId,
      showSomethingWentWrongError: EShowSomethingWentWrongError.AUTH,
    });
    yield put(authActions.setIsNotLoading());

    const status = err.response?.status;
    const error = err.response?.data;

    if (status === 401) {
      if (typeof onOtp === 'function') {
        onOtp(processId);
        // return - this is not an error case
        return;
      }
    }

    gtmTrack('signup_error', {
      signup_type: 'email',
      signup_error_message: AuthErrorTypes.emailAlreadyTaken,
    });

    // error code might not be present
    yield put(setAuthErrorCodes(error?.error_codes || []));
    // this handles the else case
    yield put(loginError());
  }
}

function* signupWithPhone(action: IAction<ISignupPhone>) {
  const { phone, onOtp, processId } = action.payload;
  const credentials = {
    phone,
    creator: false,
    organizations: [],
  };

  yield put(
    log({
      event: 'auth.saga signupWithPhone: start',
      processId,
      data: {
        credentials,
      },
    }),
  );

  try {
    yield put(resetAuthErrors());

    const { data } = yield calls.signupWithPhone(credentials);
    const { token, user } = data;

    yield put(
      log({
        event: 'auth.saga signupWithPhone: result',
        processId,
        data: {
          token,
          user,
        },
      }),
    );

    yield setMissedAuthDataSaga({
      event: 'loginWithPhone: missingAuthData',
      processId,
      user: user,
    });

    yield getOrgSettingsSaga({ organizations: user.organizations, queryOrgOnly: true });

    yield storeAndSetToken(token, 'jwt_token');

    // redirections
    const isRedirecting = yield checkForRedirectionAfterAuthAndRedirect(token, 'jwt_token');
    if (isRedirecting) {
      return;
    }

    yield redirectAfterAuth();

    // set user
    yield put(setAuth({ user: user }));
    yield applyOnboardingToken();
    yield put(signupSuccess());
  } catch (err) {
    yield call(handleHttpErrors, {
      event: 'auth.saga signupWithPhone: error',
      processId,
      error: err,
      showSomethingWentWrongError: EShowSomethingWentWrongError.AUTH,
    });
    yield put(authActions.setIsNotLoading());

    const status = err.response?.status;
    const error = err.response?.data;

    if (status === 401) {
      gtmTrack('signup', {
        signup_type: 'phone',
        signup_status: 'Success',
      });

      if (typeof onOtp === 'function') {
        onOtp(processId);
        // return - this is not an error case
        return;
      }
    }

    if (status === 400 && error?.error_codes?.includes('1')) {
      gtmTrack('signup_error', {
        signup_type: 'phone',
        signup_error_message: AuthErrorTypes.errorPhoneTaken,
      });
    }

    // error code might not be present
    yield put(setAuthErrorCodes(error?.error_codes || []));
    // this handles the else case
    yield put(loginError());
  }
}

function* setTokenAndGetUser(action) {
  // eslint-disable-next-line prefer-const
  let { token, processId } = action.payload;

  // for the URL token case
  // so that other requests can be authenticated right away
  if (token) {
    yield storeAndSetToken(token);
  }

  if (!token && window.location.hostname.includes('localhost')) {
    token = yield localStorage.getItem('token');
  }

  let attempt = 0;
  while (true) {
    try {
      yield put(
        log({
          event: 'auth.saga setTokenAndGetUser: start',
          processId,
          data: {
            attempt,
            token,
          },
        }),
      );

      const { data: user, headers } = yield calls.getLoggedInUser(token);

      yield storeAndSetToken(headers['x-access-token'] || token);

      yield put(
        log({
          event: 'auth.saga setTokenAndGetUser: result',
          processId,
          data: {
            attempt,
            user,
          },
        }),
      );

      yield setMissedAuthDataSaga({
        event: 'setTokenAndGetUser: missingAuthData',
        processId,
        user,
      });

      yield getOrgSettingsSaga({ organizations: user.organizations, queryOrgOnly: true });

      yield redirectAfterAuth({ onlyIfExists: true });
      yield put(setAuth({ user }));
      yield applyOnboardingToken();
      yield put(setIsNotLoading());
      yield put(setIsInitialAuthInProgress(false));

      // exit the loop
      return;
    } catch (error) {
      yield put(
        logError({
          event: 'auth.saga setTokenAndGetUser: error',
          processId,
          data: {
            attempt,
            error,
            errorResponse: cloneDeep(error?.response),
          },
        }),
      );

      // user is not authenticated
      // clear tokens
      if (error?.response?.status === 401 || (attempt === 4 && (!error?.response || error.response?.status === 0))) {
        yield storeAndSetToken(null);
        yield put(setAuth(null));
        yield put(setIsNotLoading());
        yield put(setIsInitialAuthInProgress(false));

        // exit the loop
        return;
      }

      if (attempt === 4) {
        yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
        yield put(setIsNotLoading());
        yield put(setIsInitialAuthInProgress(false));

        // exit the loop
        return;
      }

      // retry
      yield delay(500);
      attempt++;
    }
  }
}

function* refreshUser({ processId }: { processId?: string } = {}) {
  processId = processId || uuid();

  try {
    yield put(
      log({
        event: 'auth.saga refreshUser: start',
        processId,
      }),
    );

    const { data } = yield calls.getLoggedInUser();
    yield put(
      log({
        event: 'auth.saga refreshUser: result',
        processId,
        data: {
          user: data,
        },
      }),
    );

    yield setMissedAuthDataSaga({
      event: 'auth.saga refreshUser: missingAuthData',
      processId,
      user: data,
    });

    yield getOrgSettingsSaga({ organizations: data.organizations, queryOrgOnly: true });

    yield put(setAuth({ user: data }));
    yield applyOnboardingToken();
  } catch (error) {
    yield put(
      logError({
        event: 'refreshUser: error',
        processId,
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
    // TO DO: to check, it happens ie. when opening a quiz
  }
}

function* onSetAuth(action) {
  const { user } = action.payload || {};
  const activeOrg = yield select(orgSelector);

  const userOrg =
    user && activeOrg
      ? user.organizations.find(({ id }) => parseInt(id, 10) === parseInt(activeOrg.id, 10))
      : undefined;
  const orgName = userOrg?.name || undefined;

  yield put(
    log({
      event: 'auth.saga onSetAuth',
      data: {
        user,
        activeOrg,
        orgName,
      },
    }),
  );

  gtmSetUserId(user?.id || '');
  gtmSetUserProps(
    user?.id,
    {
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
      last_org_id: `${activeOrg?.id}`,
      last_org_name: orgName,
      user_orgs: user ? ',' + user.organizations.map(({ id }) => id).join(',') + ',' : '',
    },
    {
      user,
    },
  );

  if (userOrg) {
    yield applyLocaleIfNeeded(userOrg.branding.locale);
    yield put(setBranding(userOrg.branding));
  }

  if (user) {
    setQueryParam({ name: 'orgId', value: null, replace: true });
  }
}

function* storeAndSetToken(token: string | null, tokenType?: TokenTypes) {
  yield put(
    log({
      event: 'auth.saga storeAndSetToken',
      data: {
        token,
        tokenType,
      },
    }),
  );

  yield setBitesApiAuthorization(token);

  if (token && window.location.hostname.includes('localhost')) {
    yield localStorage.setItem('token', token);
  }

  if (token && tokenType) {
    yield localStorage.setItem('token_type', tokenType);
  } else {
    yield localStorage.removeItem('token_type');
  }
}

const clearLocalStorage = () => {
  localStorage.removeItem('botRedirectUrl');
  localStorage.removeItem('locale');
  localStorage.removeItem('token_type');
  if (window.location.hostname.includes('localhost')) {
    localStorage.removeItem('token');
  }
};

function* passwordRecovery(action: IAction<IPasswordRecoveryBody>) {
  const { email, processId, onSuccess } = action.payload;
  try {
    yield put(
      log({
        event: 'passwordRecovery: start',
        processId,
        data: { email },
      }),
    );

    yield calls.passwordRecovery({ email });
    yield put(passwordRecoverySuccess());

    if (typeof onSuccess === 'function') {
      onSuccess({ processId });
    }

    yield put(
      log({
        event: 'passwordRecovery: success',
        processId,
      }),
    );
  } catch (error) {
    if (error.response?.status < 500) {
      // this case is treated as success - proceed to the next screen
      yield put(passwordRecoverySuccess());

      if (typeof onSuccess === 'function') {
        onSuccess({ processId });
      }

      yield put(
        log({
          event: 'passwordRecovery: success',
          processId,
          error,
          errorResponse: cloneDeep(error?.response),
        }),
      );

      return;
    }

    yield call(handleHttpErrors, {
      event: 'passwordRecovery: error',
      error,
      processId,
      showSomethingWentWrongError: EShowSomethingWentWrongError.PASSWORD_RECOVERY,
    });

    yield put(passwordRecoveryError());
  }
}

function* setNewRecoverPassword(action: IAction<INewPasswordCredentials>) {
  const { token, password, processId, callback } = action.payload;
  try {
    yield put(
      log({
        event: 'setNewPassword: start',
        processId,
        data: {
          token,
          password,
        },
      }),
    );
    yield calls.setNewRecoverPassword({ token, password });

    yield put(
      log({
        event: 'setNewPassword: setNewRecoverPassword.success',
        processId,
        data: {
          token,
          password,
        },
      }),
    );

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    yield call(handleHttpErrors, {
      event: 'setNewPassword: error',
      error: err,
      processId,
      showSomethingWentWrongError: EShowSomethingWentWrongError.PASSWORD_RECOVERY,
    });

    const error = err.response?.data;

    const errorCodes = error?.errors?.map((e) => e.code);
    yield put(setNewRecoverPasswordError(errorCodes));
    yield put(loginError());
  }
}

function* finalizeUserProfileSaga({ payload }: IAction<IFinalizeProfileAction>) {
  const { email, phone, firstName, lastName, processId, onSuccess } = payload;

  const user = yield select(authSelector);

  const requestPayload: IFinalizeProfileDataRequest = {
    email: email || undefined,
    phone: phone?.length > 5 ? phone : undefined,
    first_name: firstName || undefined,
    last_name: lastName || undefined,
  };

  try {
    yield put(
      log({
        event: 'finalizeUserProfileSaga: start',
        processId,
        data: {
          userId: user.id,
          requestPayload,
        },
      }),
    );

    yield calls.finalizeUserProfile(user.id, requestPayload);

    yield put(
      log({
        event: 'finalizeUserProfileSaga: updateProfile success',
        processId,
        data: {
          userId: user.id,
        },
      }),
    );

    const { data: updatedUser } = yield calls.getLoggedInUser();

    yield put(
      log({
        event: 'finalizeUserProfileSaga: getUser success',
        processId,
        data: {
          updatedUser,
          userId: user.id,
        },
      }),
    );

    // redirect before updating is auth data missing status
    yield redirectAfterAuth({ isAuthDataMissing: false });
    yield put(setIsAuthDataMissing(false));
    yield put(refreshUserSuccess({ user: updatedUser, isSignup: false }));

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (error) {
    yield call(handleHttpErrors, {
      event: 'finalizeUserProfileSaga: error',
      processId,
      error,
      data: {
        userId: user.id,
        requestPayload,
      },
      showSomethingWentWrongError: EShowSomethingWentWrongError.SETTING_PROFILE,
    });

    yield put(refreshUserFailure());
    yield put(setRefreshUserErrors(error.response?.data?.error_codes || []));

    // this handles the else case
    yield put(loginError());
  }
}

function* getOrgSettingsSaga({ organizations, queryOrgOnly = false }) {
  const currentSettings = yield select(orgSelector);

  let orgId: string | number = getQueryParam('orgId');
  orgId = orgId ? parseInt(orgId, 10) : null;

  if (orgId) {
    if (currentSettings.id !== orgId) {
      return;
    }

    yield put(getOrgSettings(orgId));
    return;
  }

  // in case queryOrgOnly is removed in the caller
  // consider the cases of redirections from the content, ie. user info screen (org id removed from the URL ATM)
  if (queryOrgOnly) {
    return;
  }

  const microsoftContext: microsoftTeams.Context | null = yield select(microsoftTeamsContextSelector);
  const BITES_ORG_ID = 1;

  const defaultOrg =
    organizations.find((org) => {
      return org.id !== BITES_ORG_ID && (microsoftContext ? org.is_teams_related : org.is_default);
    }) || organizations.find((org) => org.id !== BITES_ORG_ID);

  if (!defaultOrg || defaultOrg.id === currentSettings.id) {
    return;
  }

  yield put(getOrgSettings(defaultOrg.id));

  const { type } = yield take([getOrgSettingsSuccess, getOrgSettingsFail]);
  if (type === getOrgSettingsFail.toString()) {
    throw new Error('Error while getting org settings');
  }
}

export function* redirectAfterAuth({ isAuthDataMissing = null, onlyIfExists = false } = {}) {
  if (isAuthDataMissing === null) {
    isAuthDataMissing = yield select(isAuthDataMissingSelector);
  }

  if (isAuthDataMissing) {
    return;
  }

  const redirectURL = getRedirectUri();

  yield put(
    log({
      event: 'auth.saga redirectAfterAuth',
      data: {
        redirectURL,
      },
    }),
  );

  if (redirectURL) {
    history.push(redirectURL);
    return;
  }

  if (onlyIfExists) {
    return;
  }

  history.push('/bites');
}

function* notifyMsAuthSuccessIfNeeded() {
  const microsoftTeamsContext = yield select(microsoftTeamsContextSelector);
  const isInsideMsTeams = !!microsoftTeamsContext;

  yield put(
    log({
      event: 'auth.saga notifyMsAuthSuccessIfNeeded',
      data: {
        microsoftTeamsContext,
        isInsideMsTeams,
      },
    }),
  );

  if (!isInsideMsTeams) {
    return;
  }

  const isRedirectedThroughBot = localStorage.getItem('botRedirectUrl');
  if (!isRedirectedThroughBot) {
    microsoftTeams.authentication.notifySuccess();
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(AuthActionTypes.LOGIN_REQUEST, login),
    takeLatest(AuthActionTypes.LOGIN_WITH_PHONE_REQUEST, loginWithPhone),
    takeLatest(AuthActionTypes.SIGNUP_WITH_EMAIL_REQUEST, signupWithEmail),
    takeLatest(AuthActionTypes.SIGNUP_WITH_PHONE_REQUEST, signupWithPhone),
    takeLatest(AuthActionTypes.SOCIAL_LOGIN_REQUEST, socialLogin),
    takeLatest(AuthActionTypes.LOGOUT, logout),
    takeLatest(AuthActionTypes.REFRESH_USER, refreshUser),
    takeLatest(AuthActionTypes.SET_TOKEN_AND_GET_USER, setTokenAndGetUser),
    takeLatest(AuthActionTypes.PASSWORD_RECOVERY_REQUEST, passwordRecovery),
    takeLatest(AuthActionTypes.SET_NEW_RECOVERY_PASSWORD_REQUEST, setNewRecoverPassword),
    takeLatest(AuthActionTypes.SET_AUTH, onSetAuth),
    takeLatest(AuthActionTypes.REFRESH_USER_SUCCESS, onSetAuth),
    takeLatest(AuthActionTypes.FINALIZE_USER_PROFILE, finalizeUserProfileSaga),
  ]);
}
