import React, { FC, memo, useCallback, useMemo } from 'react';
import AuthError from './AuthError';
import styled from 'styled-components';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmailForm from './EmailForm';
import { TERMS_CONDITIONS_URL } from '../../../utils/auth';
import { EAuthMethods } from '../../../store/authForm/authForm.types';
import SocialButtons from './SocialButtons';
import { log } from '../../../store/tracking/tracking.slice';
import { allowedAuthMethodsSelector } from '../../../store/organization/organization.selectors';
import PhoneNumberAuth from './PhoneNumber/PhoneNumberAuth';
import useAuthError from '../hooks/useAuthError';
import useAuthTitles from '../hooks/useAuthTitles';

const AuthForm: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allowedAuthMethods = useSelector(allowedAuthMethodsSelector);

  const formError = useAuthError();
  const { title, subtitle, texts } = useAuthTitles();

  const hasCredentialsMethod = useMemo(
    () =>
      !!allowedAuthMethods.find((method) => method.type === EAuthMethods.EMAIL) ||
      !!allowedAuthMethods.find((method) => method.type === EAuthMethods.PHONE),
    [allowedAuthMethods],
  );

  const defaultMethod = useMemo(() => allowedAuthMethods.find((method) => method?.isDefault), [allowedAuthMethods]);

  const handleTermsConditionsPress = useCallback(() => {
    dispatch(
      log({
        event: 'AuthForm.handleTermsConditionsPress',
      }),
    );
  }, [dispatch]);

  return (
    <S.Container>
      <Header title={title} description={subtitle} texts={texts} />
      <AuthError isVisible={!!formError}>{formError}</AuthError>
      <S.Form hasCredentialsMethod={hasCredentialsMethod}>
        {hasCredentialsMethod ? (
          defaultMethod?.type === EAuthMethods.EMAIL ? (
            <EmailForm isAuthForm />
          ) : (
            <PhoneNumberAuth isAuthForm />
          )
        ) : null}
        {allowedAuthMethods.length > 1 && !!defaultMethod && (
          <S.Separator>
            <S.SeparatorLine />
            <S.SeparatorText>{t('authForm.or')}</S.SeparatorText>
            <S.SeparatorLine />
          </S.Separator>
        )}
        <SocialButtons />
        <S.TermsAndConditions>
          {t('authForm.agreeToOur')}
          <S.TermsAndConditionsLink href={TERMS_CONDITIONS_URL} target={'blank'} onClick={handleTermsConditionsPress}>
            {t('authForm.terms')}
          </S.TermsAndConditionsLink>
        </S.TermsAndConditions>
      </S.Form>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    @keyframes slideInFromLeft {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
  `,
  Form: styled.div<{ hasCredentialsMethod }>`
    margin-top: ${({ hasCredentialsMethod }) => (hasCredentialsMethod ? 32 : 24)}px;
    align-items: center;
  `,
  Header: styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
  HeaderTitle: styled.span`
    color: ${({ theme }) => theme.colors.text};
    font-size: 20px;
    line-height: 28px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  `,
  HeaderDescription: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
  `,
  ForgotPassword: styled.div`
    cursor: pointer;
  `,
  ForgotPasswordText: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    margin-top: 24px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
  Separator: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 24px 0;
  `,
  SeparatorLine: styled.div`
    flex: 1;
    height: 1px;
    background: ${({ theme }) => theme.colors.lightGray19};
  `,
  SeparatorText: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    line-height: 16px;
    margin: 0 10px;
  `,
  TermsAndConditions: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 295px;
    margin-top: 24px;
    margin-bottom: 30px;
    align-items: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  `,
  TermsAndConditionsLink: styled.a`
    cursor: pointer;
    margin-left: 4px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
};

export default memo(AuthForm);
